@import '../../shared/styles/variables';

.ca-testimonial-div {
  border: 4px solid $orange;
  border-radius: 5px;
}

.ca-testimonial-pale-background {
  background: linear-gradient(to right, white 30%, $pale 1px, $pale);
}
