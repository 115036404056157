@import '../../shared/styles/variables';

.ca-contact-us-header {
	height: 4rem;
}

.ca-contact-us-button {
	button {
		background-color: $green;
		border-color: $green;

		&:hover {
			background-color: $green!important;
			border-color: $green!important;
		}

		&:focus {
			background-color: $green!important;
			border-color: $green!important;
		}
	}
}

.ca-contact-us-pale-background {
	background: linear-gradient(to top, white 30%, $pale 1px, $pale 60%);
}

.ca-contact-us-error {
	p {
		color: $red;
	}

}

.ca-contact-us-success {
	p {
		color: $orange;
	}
}
