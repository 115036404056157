@import '../../shared/styles/variables';



.ca-title-pale-background {
	background: linear-gradient(to left, white 20%, $pale, $pale 80%);
}

@media (min-width: 992px) {
	.services-card-wrapper {
		height: 20rem;
	}
}
