@import '../../shared/styles/variables';

.ca-navbar-button {
	background-color: $orange!important;
	border-color: $orange!important;
	padding: .380rem 1.95rem!important;
	a {
		color: white;

		&:hover {
			color: white;
		}
	}

}
