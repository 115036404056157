@import './shared/styles/variables';

.ca-footer-social-wrapper {
	&:hover {
		cursor: pointer;
	}
}

.ca-footer-linkedin-wrapper {
	border: 2px solid white;
	padding: 1px 2px;
	border-radius: 5px;

	svg {
		color: white;
	}
}
