
@import 'bootstrap/scss/mixins';

$enable-responsive-font-sizes: true;

// colors

$blue: #3F84E5;
$green: #43AA8B;
$orange: #FF6F59;
$pale: #FEF9EE;
$brown: #63585E;
$grey: rgb(162, 163, 165);
$red: red;

p {
	margin-bottom: 0;
	@include font-size(1.2rem);
	font-family: 'Open Sans' ;
}

.ca-grey {
	color: $grey;
}

.ca-orange {
	color: $orange;
}

.ca-red {
	color: red;
}

.ca-background-brown {
	background-color: $brown;
}

.ca-white {
	color: white;
}

.ca-title-font {
	@include font-size(2.5rem);
	font-weight: 600;
}

.ca-subtitle-font {
	@include font-size(1.3rem);
}

.ca-3-line-height {
	line-height: 3rem;
}

.ca-2-line-height {
	line-height: 2rem;
}

.ca-oblique {
	font-style: oblique;
}

.ca-w-65 {
	width: 65%!important;
}
