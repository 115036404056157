@import '../../shared/styles/variables';

.ca-about-our-missing-div {
	background: linear-gradient(to top,$pale 1px, $pale);
}

@media (min-width: 992px) {
	.ca-margin-top-checklist {
		margin-top: 8rem;
	}
}
