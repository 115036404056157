@import '../../shared/styles/variables';

.main-screen-ca-jumbotron {
	background-color: transparent!important;
}

.ca-main-screen-text-section {
	background: linear-gradient(to bottom, $pale 90%, white);
}

.ca-main-screen-text-section--small {
	background-color: $pale;
}
